@use 'common'

.LecturersBox
	padding: 2rem

	@media (min-width:768px)
		padding: 0 45px
	@media (min-width:1200px)
		padding: 0 90px

.Lecturer
	display: flex
	flex-direction: column
	padding: 35px 0
	@media (min-width:768px)
		flex-direction: row

.Title
	+common.headline
	padding: 3rem 0
	margin: 0

.Name
	+common.smallHeadline
	margin: 0

.Dash
	margin: 0

.Image
	max-width: 150px
	border-radius: 5px
	overflow: hidden
	width: 145px
	height: 130px
	position: relative
	margin-bottom: 1rem

.LecturerInfo
	min-width: 250px
	max-width: 250px

.Text
	max-width: 650px
	font-size: 20px
	line-height: 35px
	margin: 2rem 0
	@media (min-width: 768px)
		margin: 0
