@use 'common'

@keyframes translateUp
	0%
		transform: translateY(100%)
	100%
		transform: translateY(0%)

.wrapper
	z-index: 10
	padding: 25px
	background-color: common.$secondaryColor
	position: fixed
	bottom: 0
	right: 0
	left: 0
	animation-name: translateUp
	animation-duration: 1s
	border-radius: 10px
	&.close
		display: none
	@media ( min-width: 768px)
		padding: 25px 35px

.text
	line-height: 1.4
	font-size: 14px
	@media ( min-width: 768px )
		font-size: 1rem

.title
	display: flex
	justify-content: space-between
	font-weight: 700
	margin-bottom: 15px
	font-size: 20px
	@media ( min-width: 768px )
		font-size: 30px

.crossButton
	cursor: pointer
	width: 20px
	display: flex
	justify-content: space-between
	&:hover
		.line1,
		.line2
			background: darken(#000, 20%)

.line1,
.line2
	width: 2px
	height: 25px
	background: #000
	border-radius: 3px

.line1
	transform: rotate(-45deg)
	transform-origin: top right

.line2
	transform: rotate(45deg)
	transform-origin: top left

.checkboxWrapper
	display: none
	&.displayCheckbox
		display: block

.checkBox
	cursor: pointer
	margin-top: 1em
	display: grid
	align-items: center
	grid-template-columns: 1.15em auto
	gap: 0.5em
	font-size: 1rem

	input
		cursor: pointer
		margin: 0
		-webkit-appearance: none
		appearance: none
		width: 1.5em
		height: 1.5em
		border: 1px solid #000
		transition: background .3s
		position: relative
		border-radius: 5px
		&:checked
			background: common.$mainColor
			&::before
				background: common.$secondaryColor
				content: ""
				position: absolute
				top: 0
				left: 0
				width: 100%
				height: 100%
				clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%)
		&:disabled
			background: common.$mainColor
			opacity: .7
			cursor: default
			&::before
				background: common.$secondaryColor
				content: ""
				position: absolute
				top: 0
				left: 0
				width: 100%
				height: 100%
				clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%)
			&+ span
				opacity: .7
				cursor: default

.inputWrapper
	display: flex
	justify-content: center
	align-items: center

.rejectButton,
.consentButton,
.detailButtonTitle,
.backButton
	font-weight: 700
	padding: 0.75rem 1rem
	font-size: 14px
	cursor: pointer
	background: common.$mainColor
	border: none
	border-radius: 25px

	&:hover
		background: darken(common.$mainColor, 10%)
	@media ( min-width: 480px )
		margin-right: 40px
	@media ( min-width: 768px )
		font-size: 16px

.rejectButton
	margin-bottom: 30px
	@media ( min-width: 768px )
		margin-bottom: 0

.detailButtonTitle
	margin-top: 30px

.buttonWrapper
	margin-top: 20px
	&.hideButtons
		display: none
