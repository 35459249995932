@use 'common'

.Buttons
	display: flex
	flex-direction: column
	justify-content: center
	@media (min-width: 768px)
		flex-direction: row

.Button
	background-color: common.$mainColor
	display: flex
	align-items: center
	margin: 0.5rem 14px
	padding: 0.5rem 1rem
	border-radius: 25px
	transition: 0.3s ease-in-out
	@media (min-width: 768px)
		margin: 2rem 14px
	&:hover
		transform: scale(1.05)

.Title
	font-weight: 700
