@use 'common'

.Wrapper
	padding: 2rem 0

.Button
	background-color: common.$mainColor
	display: flex
	width: fit-content
	margin: auto
	border-radius: 10px
	transition: 0.3s ease-in-out
	&:hover
		transform: scale(1.05)

.LinkBox
	padding: 1rem
	display: flex

.Link
	+common.smallHeadline
	padding-left: 1rem
	margin-right: 4rem
	max-width: 180px
