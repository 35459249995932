@use 'common'
@use "sass:math"

.CourseContainer
	padding: 1rem
	width: 100%
	height: 100%
	min-height: 335px

.CourseBox
	position: relative
	height: 100%

.TileImage
	border-radius: 10px
	overflow: hidden
	width: 100%
	height: 100%
	position: absolute

	&::after
		content: ""
		position: absolute
		top: 0
		right: 0
		bottom: 0
		left: 0
		background: linear-gradient(0deg, rgba(0,0,0,0.6) 0%, rgba(255,255,255,0) 100%)

.CourseTitleContainer
	position: relative
	margin-top: auto

.CourseTitleBox
	display: flex
	flex-direction: column
	align-items: flex-start

.CourseTitle
	+common.smallHeadline
	color: #fff
	text-align: left

.Dash
	color: #fff

.CourseDescription
	font-size: 14px
	line-height: 17px
	color: common.$secondaryColor
	text-align: left

.TextBox
	width: 100%
	display: flex
	flex-direction: column
	justify-content: space-between
	position: relative
	padding: 1rem
	height: 100%
	min-height: 300px
	@media (min-width:768px)
		padding: 21px

.Date
	float: left
	padding-left: 4px
	padding-bottom: 4px
	width: 25%

	&Box
		background-color: common.$secondaryColor
		width: 100%
		white-space: nowrap
		padding: .125em
		text-align: center
		border-radius: 10px
		display: flex
		align-items: center
		justify-content: center
		font-weight: 700
		font-size: 14px

.PlusDateBox
	width: 15%

.PlusDates
	color: #fff

.LecturerImageBox
	float: right
	display: flex
	flex-direction: row-reverse

.LecturerImage
	width: 47px
	height: 56px
	border-radius: 10px
	border: 2px solid #fff
	margin-left: -20px
	position: relative
	div
		display: block
		height: 100%
		border-radius: 10px

	&:nth-last-child(1)
		margin-left: 0

.LecturerImageBox,
.Date
	height: 60px
