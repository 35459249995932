@use 'common'
@use 'sass:math'

.InfoBoxes
	display: flex
	flex-wrap: wrap
	justify-content: space-evenly
	margin: 3rem auto
	max-width: 1200px

.InfoBox
	position: relative
	width: 100%
	padding: 3rem 2rem
	@media (min-width: 992px)
		padding: 1rem

	&:nth-child(1)
		.Image
			bottom: -23px
			left: 100px
			background-image: url("/images/TwoLines.svg")
			width: 89px
			height: 26px

		.Subtitle
			margin-top: 2rem

	&:nth-child(2)
		.Image
			top: -35px
			right: 0
			left: 0
			margin: auto
			background-image: url("/images/FourLines.svg")
			width: 58px
			height: 25px

	&:nth-child(3)
		.Image
			top: -30px
			left: -30px
			background-image: url("/images/Star.svg")
			width: 49px
			height: 39px
			@media (min-width: 992px)
				top: -25px
				left: -40px

	&:nth-child(4)
		.Image
			top: -45px
			left: 60px
			background-image: url("/images/Hedgehog.svg")
			width: 83px
			height: 48px

	@media (min-width: 768px)
		width: math.div(100%, 2)

	@media (min-width: 992px)
		width: math.div(100%, 4)

.Cover
	position: relative

.Image
	position: absolute
	background-repeat: no-repeat

.Title
	+common.headline
	margin-bottom: 0
	margin: 0

.Subtitle
	margin-top: 0.25rem

.Text
	color: common.$darkGrey
