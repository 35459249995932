@use 'common'
@use 'sass:math'

.Course
	--maybePaddingRight: 1

.CourseImage
	position: relative
	width: 100%
	height: 100%
	padding-top: math.div(100%, math.div(2880, 944))

.Header
	display: flex
	flex-wrap: wrap
	flex-direction: column
	align-items: center
	background-color: common.$secondaryColor
	max-width: 1200px
	border-radius: 10px
	padding: 1rem

	@media (min-width: 768px)
		padding: 0.5rem 0 0.5rem 2rem
		flex-direction: row

.HeaderImage
	@media (min-width: 768px)
		width: 20%
		padding: 1rem

.HeaderColumn
	color: common.$darkGrey
	font-size: 12px
	line-height: 14.4px
	padding: 0 0.5rem
	@media (min-width: 768px)
		width: math.div(100%, 3)

.LoginBoxWrap
	top: 0
	bottom: 0
	right: 0
	z-index: 1
	pointer-events: none

.LoginBox
	background-color: common.$mainColor
	padding: 1rem
	border-radius: 10px
	display: flex
	justify-content: space-between
	align-items: flex-end
	pointer-events: auto

	@media (min-width: 500px)
		padding: 1.25rem 2rem

	@media (min-width: 1024px)
		padding: 33px 25px
		flex-direction: column
		align-items: flex-start
		bottom: auto
		top: 0
		margin: 0 40px
		min-width: 250px
		min-height: 350px

	@media (min-width: 1440px)
		margin: 0 80px

	.InfoValue
		font-weight: 400
		display: flex
		align-items: center
		margin: 0
		&:nth-child(1)
			padding-top: 0.5rem
			@media (min-width: 768px)
				padding-top: 1rem

	.Date
		font-weight: 400

.StickyBox
	position: fixed
	padding-top: math.div(100%, math.div(2880, 944))
	width: 100%
	z-index: 2
	bottom: 0px
	@media (min-width: 1024px)
		bottom: 15px
		display: flex
		justify-content: flex-end
		align-items: flex-end
		top: 0

.LoginTitleBoxWrapper
	width: 50%
	@media (min-width: 425px)
		width: 60%
	@media (min-width: 1024px)
		width: min-content

.LoginTitleBox
	+common.smallHeadline
	margin: 0
	.TitleTop
		display: flex
		flex-direction: column

.LoginLink
	background-color: common.$secondaryColor
	border-radius: 25px
	width: fit-content
	padding: 0.5rem 0.5rem 0.4rem 1rem
	position: absolute
	right: 10px
	a
		text-decoration: none
	@media (min-width: 1024px)
		position: relative
		right: 0

.LecturersTitle
	+common.headline
	padding-top: 2rem
	margin: 0
	text-align: center
	@media (min-width: 768px)
		text-align: left
		padding: 4rem 90px 3rem

:global(.scrolledDown)
	@media (max-width: 1023.9px)
		.LoginBox
			.LoginLink
				position: relative
				right: -10px
				text-align: center
		.LoginTitleBox

			.TitleTop
				@media (max-width: 768px)
					&::after
						content: ""

		.LoginBox:not(.isOpen)
			align-items: center
			.LoginLink
				position: relative
				right: -10px
			.TitleTop
				font-size: 20px
				&::after
					content: "..."
			.Dash,
			.TitleBottom,
			.InfoBox
				display: none
				@media (min-width: 1024px)
					display: block
