@use 'common'
@use 'sass:math'

.Gallery
	display: flex
	flex-wrap: wrap
	justify-content: center
	max-width: 768px
	margin: auto

.ImageBox
	width: 50%
	position: relative
	padding: 24px

	&:nth-child(1):nth-last-child(odd)
		width: 100%

.Image
	position: relative
	border-radius: 10px
	overflow: hidden

	&::before
		display: block
		content: ""
		padding-top: 100% * math.div(241, 347)
