@use 'common'

.ArrowLink
	display: flex
	align-items: center
	font-size: 16px
	line-height: 19.2px
	font-weight: 700
	text-decoration: underline
	z-index: 2
	&:hover
		.Arrow
			transform: translateX(10px)

.Text
	padding: 0.5rem 0.5rem 0.5rem 0

.Arrow
	transition: 0.3s ease-in-out
