@use 'common'

.Cover
	display: flex
	justify-content: center
	align-items: center

.TextBox
	width: 50%

.Title, .Text
	margin: 0 180px 0 90px

.Title
	+common.title

.Image
	width: 50%
