@use 'common'
@use "sass:math"

.References
	max-width: 1200px
	margin: auto

.Title
	+common.smallHeadline
	padding: 3rem 0
	margin: 1rem
	margin-top: 0
	@media (min-width: 768px)
		text-align: center
		padding-top: 6rem

.PersonBox
	width: math.div(100%, 3)
	display: flex
	flex-direction: column
	align-items: flex-start
	margin: 1rem
	@media (min-width: 768px)
		width: 20%
		margin: 2rem

.Reference
	display: flex
	@media (max-width: 768px)
		flex-direction: column-reverse
	@media (min-width: 768px)
		padding-bottom: 7.5rem
		&:nth-child(2n+1)
			flex-direction: row-reverse

.QuotationMarks
	color: common.$mainColor

.Text
	+common.smallHeadline
	margin: 1rem
	@media (min-width: 768px)
		margin: 2rem
		font-size: 45px
		line-height: 60px
		letter-spacing: -0.9px
		width: 70%
		max-width: 600px

.Name
	+common.smallHeadline
	margin: 0

.Position
	font-size: 14px

.Image
	margin-bottom: 11px
	border-radius: 10px
	overflow: hidden
	line-height: 0

.ExpandedBtnWrapper
	display: flex
	justify-content: flex-end
	width: 100%
	padding-bottom: 4.5rem
	padding-right: 2rem
	&:hover
		.Arrow
			transform: translateX(10px)

	@media (min-width: 768px)
		margin-top: -7rem

.Arrow
	display: flex
	transition: 0.3s ease-in-out

.ExpandedBtn
	border: none
	background-color: transparent
	font-size: 16px
	line-height: 19.2px
	font-weight: 700
	cursor: pointer
	padding: 0
	text-decoration: underline

.isExpanded
	display: none
