@use 'common'
@use "sass:math"

.Banners
	display: flex
	flex-direction: column
	justify-content: center
	margin: auto
	padding: 2rem 0
	@media (min-width: 768px)
		flex-direction: row
		padding: 4rem 0

	@media (min-width: 1024px)
		:global(.pageType-course) &
			justify-content: flex-start
.Banners + .Banners
	padding-top: 0

.BannerBox
	display: flex
	width: 100%
	justify-content: center

.BoxWhite
	background-color: #fff

.Banner
	background-color: common.$secondaryColor
	border-radius: 10px
	padding: 29px
	margin: 15px

.PrimaryText
	+common.smallHeadline
	margin-top: 0

.Highlighted
	background-color: common.$mainColor
	border-radius: 10px
	margin: 1rem
	padding: 1rem
	padding: 2rem

.FullWithTwoImages
	padding: 2rem

	.Box
		@media (min-width: 768px)
			width: math.div(100%, 3)

	.PrimaryText
		+common.headline
		text-align: center
		margin: 0

	.SecondaryText
		text-align: center
		margin: 1rem 0
		a
			text-decoration: underline
			color: common.$mainColor

		p
			margin: 0

	.TextBox
		display: flex
		flex-direction: column
		justify-content: center
		align-items: center
		@media (min-width: 1200px)
			padding: 0 1rem

	.TertiaryText
		margin: 2rem 0
		a
			color: common.$mainColor
			text-decoration: underline

	.Link
		background-color: common.$mainColor
		border-radius: 25px
		padding: 0.3rem 0.9rem 0.3rem 1.2rem
		width: fit-content
		a
			text-decoration: none

	@media (min-width: 550px)
		display: flex
		justify-content: center
		align-items: center

.Full
	padding: 1rem

	@media (min-width: 550px)
		display: flex
		justify-content: space-around
		align-items: center

	@media (min-width: 768px)
		width: 100%
	@media (min-width: 992px)
		padding: 1rem 3rem 1rem 2rem

	@media (min-width: 1200px)
		padding: 1rem 6rem 1rem 4rem

	.ImageBox
		width: 50%
		margin: 1rem auto 0

		@media (min-width: 550px)
			width: 15%
			margin: 0

	.PrimaryText
		margin-bottom: 0

	.PrimaryBox
		@media (min-width: 550px)
			width: 50%

	.SecondaryBox
		display: flex
		justify-content: center
		margin: 1rem 0
		@media (min-width: 550px)
			width: 35%

.TwoThirdNewsletter
	.SecondaryBox
		display: flex
		align-items: center
		font-weight: 700
		text-decoration: underline
		cursor: pointer
		&:hover
			.Arrow
				transform: translateX(10px)

	.NewsletterLink
		margin-right: 0.5rem
		border: none
		background-color: transparent
		font-size: 16px
		line-height: 19.2px
		font-weight: 700
		padding: 0
		cursor: pointer

		&:focus
			outline: none

		p
			margin: 0

	.Arrow
		transition: 0.3s ease-in-out

.FullNewsletterWrapper
	width: 100%
	display: flex
	justify-content: center

.FullNewsletter
	padding: 1rem
	margin: 1rem
	@media (min-width: 992px)
		padding: 0.5rem 3rem 0.5rem 2rem
	@media (min-width: 1200px)
		padding: 0.5rem 6rem 0.5rem 4rem

	.SecondaryBox
		display: flex
		align-items: center
		font-weight: 700
		text-decoration: underline
		cursor: pointer
		&:hover
			.Arrow
				transform: translateX(10px)

	.NewsletterLink
		margin-right: 0.5rem
		border: none
		background-color: transparent
		font-size: 16px
		line-height: 19.2px
		font-weight: 700
		padding: 0
		cursor: pointer

		&:focus
			outline: none

	.Arrow
		transition: 0.3s ease-in-out

	.ImageBox
		display: none
		@media ( min-width: 768px)
			display: block

.TwoThird
	.Box
		display: flex

	.TextBox
		width: 75%
		padding-right: 2rem

	.ImageBox
		width: 25%
		display: flex
		justify-content: center

	.Links
		display: flex
		flex-direction: column
		@media (min-width: 768px)
			flex-direction: row

		.Link + .Link
			margin-left: 1rem

.OneThird
	display: flex
	min-height: 250px

	.TextBox
		display: flex
		flex-direction: column
		justify-content: space-between
		z-index: 2

	.ImageBox
		display: flex
		justify-content: flex-end
		margin-right: -12px

.OneThirdWithButton
	.PrimaryImage
		margin-bottom: 2rem

	.Link
		background-color: common.$mainColor
		border-radius: 25px
		padding: 0.3rem 0.9rem 0.3rem 1.2rem
		width: fit-content
		a
			text-decoration: none

.OneThirdWithEmbed
	padding: 29px 0.5rem

	.TitleBox,
	.TextBox
		padding: 0 1rem

	.TitleBox
		display: flex
		justify-content: space-between
		align-items: center

	.TextBox
		max-width: 270px

	.iFrame
		border-radius: 10px
		border: none
		background-color: #fff

.Full,
.FullWithTwoImages
	max-width: 1200px

.BannersBox
	max-width: 950px

.fullWithTwoImages
	display: flex
	justify-content: center

.fullWithTwoImages,
.full
	width: 100%

.twoThird,
.twoThirdNewsletter
	width: 100%
	@media (min-width: 768px)
		width: math.div(100%, 3) * 2

.oneThird,
.oneThirdWithEmbed,
.oneThirdWithButton,
.oneThirdWithTwoLinks
	width: 100%
	@media (min-width: 768px)
		width: math.div(100%, 3)

.TwoImagesBox
	display: flex
	align-items: center
	p
		margin: 0

.NewsletterImage
	max-width: 20%
	@media (min-width: 768px)
		max-width: 140px

.isOpen
	.NewsletterForm
		z-index: 22
		display: flex
		flex-direction: column
		background-color: common.$mainColor
		border-radius: 10px
		margin: 1rem
		position: fixed
		top: 10%
