@use 'common'
@use 'sass:math'

.Form
	display: flex
	flex-direction: column

	.title
		+common.smallHeadline
		margin-bottom: 1em

	.SuccessBoxAnchor
		display: block
		position: relative
		top: -250px
		visibility: hidden

	.SuccessBox
		background-color: common.$mainColor
		padding: 2rem
		margin: 1rem 0
		border-radius: 10px

	.SuccessTitle
		margin-bottom: 2rem

	input,
	textarea
		width: 100%
		border-radius: 5px
		border: none
		margin: 0.5rem 0
		background-color: common.$secondaryColor
		padding: 1rem
		&:focus
			background-color: #F7F7F7
			outline: none

	input
		height: 43px

	textarea
		max-width: 100%
		min-height: 150px

	label
		margin-bottom: 0.3rem

	.Field
		margin-top: 1rem

		&.hidden
			margin-top: 0

	.Fields.FormFieldsHidden
		display: none

	.Submit
		border: none
		background-color: transparent
		width: fit-content
		text-decoration: underline
		cursor: pointer
		margin: 0
		font-size: 16px
		font-weight: 700
		height: auto
		border: none
		padding: 0.75rem

.SubmitBox
	display: flex
	align-items: center
	width: fit-content
	background-color: common.$secondaryColor
	border-radius: 30px
	padding: 0 1rem
	svg
		transition: 0.3s ease-in-out

	&:hover
		svg
			transform: translateX(10px)

	&.disabled
		opacity: 0.5
		cursor: wait

		.Submit
			cursor: wait

.Select
	background-color: common.$secondaryColor
	width: 100%
	height: 43px
	border-radius: 5px
	border: none
	margin: 0.5rem 0 1rem
	padding: 0 1rem
	.Disabled
		opacity: 0.5
		color: red

.NewsletterRadioBox
	display: flex
	color: common.$darkGrey

	.GdprBtn
		width: auto
		height: auto
		margin-right: 0.25rem

	.RadioLabel
		display: flex
		justify-content: center
		align-items: center

	.Radio
		width: auto
		height: auto
		margin: 0 0.25rem

.ItemNote
	margin-top: 0
	font-size: 0.8rem
	color: common.$darkGrey

	.LabelCharacter
		color: common.$mainColor

	a
		text-decoration: underline
		font-weight: 700

.errorMessage
	border: 2px solid red
	border-radius: 4px
	padding: 0.8rem 1rem
	color: red
	margin-bottom: 20px
