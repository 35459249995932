@use 'common'

.list
	display: flex
	align-items: center
	list-style-type: none
	margin: 0

.item
	display: flex
	margin: 0 4px

	&.isActive
		font-weight: bold

.link
	display: block
	padding: 6px 12px
	background-color: common.$secondaryColor
	border-radius: 4px
	transition-property: background-color
	transition-duration: 0.2s
	transition-timing-function: ease-out

	&:hover
		background-color: common.$mainColor

.empty
	margin: 0 4px
