@use 'common'

.DarkBackground
	background-color: common.$secondaryColor

.BlogPosts
	display: flex
	flex-direction: column
	padding: 2rem 0

.HeadlineBox
	display: flex
	flex-direction: column
	align-items: center
	margin: 2rem 0

	&.HideThumbnail
		flex-direction: row-reverse
		justify-content: flex-end
		margin: 0 1rem
		@media (min-width: 768px)
			margin: 0 3.5rem
		@media (min-width: 1200px)
			width: 100%
			max-width: 1200px
			margin: auto
			padding-left: 3.25rem

.Image
	&.HideThumbnail
		max-width: 125px

.TitleBox
	display: flex
	flex-direction: column
	margin-top: 1rem
	&.HideThumbnail
		align-items: flex-start
		margin-top: 0
		margin-right: 2rem

.Title
	+common.headline
	text-align: center
	margin: 0

	&.HideThumbnail
		+common.smallHeadline

.Dash
	margin: 0

.BlogPostsBox
	display: flex
	justify-content: center
	flex-wrap: wrap
	margin-top: 2rem
	margin-bottom: 3rem
	max-width: 1200px
	@media (min-width: 1200px)
		margin: auto

	&::after
		content: ""
		flex: auto

.ExpandedBtnWrapper
	display: flex
	justify-content: flex-end
	width: 100%
	padding-top: 2rem
	padding-right: 45px
	&:hover
		.Arrow
			transform: translateX(10px)

.Arrow
	display: flex
	transition: 0.3s ease-in-out

.ExpandedBtn
	border: none
	background-color: transparent
	font-size: 16px
	line-height: 19.2px
	font-weight: 700
	cursor: pointer
	padding: 0
	text-decoration: underline

.isExpanded
	display: none

.ButtonBox
	display: flex
	align-items: center
	text-decoration: underline
	font-weight: 700
	max-width: 1200px
	margin: auto
	padding: 0 0 3rem 1rem
	@media (min-width: 768px)
		padding: 3rem 45px
		justify-content: flex-end

.Arrow
	margin-left: 0.4rem
