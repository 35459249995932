@use 'common'
@use "sass:math"

.TimeLineContainer
	padding-top: 5rem
	@media (min-width:768px)
		padding-top: 7rem

.TitleBox
	display: flex
	align-items: center
	max-width: 200px
	margin: auto

	svg
		margin-top: 50px
		margin-left: -50px
.Title
	+common.smallHeadline
	text-align: center
	padding: 33px 0

.TimelineBoxes
	display: flex
	flex-direction: column
	align-items: center

.TimelineBox
	min-width: 250px
	max-width: 250px
	margin: 25px 33px
	@media (min-width: 768px)
		margin: 33px

.Headline
	+common.headline
	margin: 0

.Text
	margin-top: 0.5rem

.Button
	display: flex
	align-items: center
	border: none
	background-color: transparent
	cursor: pointer
	font-weight: 700
	text-decoration: underline
	margin: auto
	padding: 0 2rem 3rem
	svg
		margin: 0.25rem
