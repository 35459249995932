@use '../vars'

=title
	font-size: 40px
	line-height: 45px
	letter-spacing: -0.9px
	font-weight: 700
	@media (min-width: 768px)
		font-size: 65px
		line-height: 65px
	@media (min-width: 1400px)
		font-size: 70px
		line-height: 75px
