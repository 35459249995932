@use 'common'

.ArticleCredentials
	max-width: 768px
	margin: auto

	.Text
		font-size: 0.8rem
		opacity: 0.8
		text-overflow: ellipsis
		overflow: hidden
		white-space: nowrap
		padding: 0 1rem
