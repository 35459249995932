@use 'common'

.QuestionAnswer
	margin: 4rem 0
	.QuestionWrapper
		background-color: common.$secondaryColor
		padding: 1rem
		border-radius: 10px
		@media (min-width: 768px)
			padding: 2rem
		.TitleWrapper
			display: flex
			justify-content: space-between
		.LabelTitle
			font-size: 18px
			margin: 0
		.Title
			font-size: 1.5rem
			width: 90%
		.Date
			background-color: #fff
			padding: 0 0.5rem
			border-radius: 10px
			font-weight: 700
			margin: 0
			display: flex
			align-items: center
			text-align: center
			white-space: nowrap
			height: fit-content
			padding: 1rem
		.Question
			margin-top: 3rem
			color: common.$darkGrey

		.AnswerBox
			border: none
			background-color: transparent
			text-align: start
			font-size: 1rem
			line-height: 25px
			display: flex
			margin: 1rem 0 0 auto
		.AnswerBtn
			display: flex
			justify-content: flex-end
			align-items: center
			font-size: 1rem
			text-decoration: underline
			font-weight: 700
			color: #000
			cursor: pointer
			width: fit-content
			margin: 0 0 0 auto

			p
				margin: 0
			svg
				transition: 0.3s ease-in-out
				margin-left: 0.75rem

			&:hover
				svg
					transform: scale(1.1)

		.Answer,
		.CategoryType
			text-align: start

	.Answer
		background: #fff
		border-radius: 10px
		padding: 2rem
		z-index: 1

	.CategoryType
		margin-top: 0.25rem
		font-weight: 700
		color: #000
	.CategoryTypeLink
		color: common.$darkGrey
		text-decoration: underline
		color: common.$darkGrey
		font-weight: 400
