@use 'common'
@use "sass:math"

.BoxWithList
	display: flex
	flex-direction: column
	flex-wrap: wrap
	padding: 2rem 1rem
	@media (min-width: 768px)
		padding: 2rem
		flex-direction: row
	@media (min-width: 1200px)
		padding: 2rem 5rem

.BoxContainer
	@media (min-width: 768px)
		width: math.div(100%, 2)
	@media (min-width: 992px)
		width: math.div(100%, 3)

.Box
	background-color: #fff
	border-radius: 10px
	padding: 25px
	margin: 13px

.Title
	+common.smallHeadline()
	padding-bottom: 0.5rem
	margin-top: 0
	border-bottom: 1px solid #D8D8D8
	margin-bottom: 1rem

.List
	padding: 0 0 0 19px

.BoxItem
	font-size: 16px
	line-height: 19px
	padding: 10px 0
	color: common.$darkGrey
	a
		text-decoration: underline
