@use 'common'
@use "sass:math"

.TopicPage
	background-color: common.$secondaryColor

.Topics
	display: flex
	flex-wrap: wrap
	max-width: 1200px
	margin: auto
	@media (min-width: 1450px)
		max-width: 1450px

.Topic
	background-color: common.$lightGrey
	border-radius: 10px
	margin: 1rem
	display: flex
	padding: 2rem
	transition: 0.3s ease-in-out
	height: 250px
	width: 100%
	@media (min-width: 768px)
		width: auto
		@media (min-width: 992px)
			width: calc(100% / 4 - 2rem)
		@media (min-width: 1450px)
			width: calc(100% / 5 - 2rem)

		&:hover
			transform: scale(1.05)
			.Button
				color: #000

.TextBox
	display: flex
	flex-direction: column
	justify-content: space-between

.TitleBox
	display: flex
	flex-direction: column

.Title
	+common.smallHeadline

.Button
	color: #979797
	border-bottom: 1px solid #979797
	width: fit-content
	font-weight: 700

.Image
	width: 25%
	position: relative

.TypeDouble
	justify-content: space-between
	@media (min-width: 768px)
		width: calc(100% / 2 - 2rem)
	@media (min-width: 1200px)
		width: calc(2 * (100% / 5) - 2rem)
