@use 'common'
@use "sass:math"

.BlogPage
	background-color: common.$secondaryColor

.BlogPosts
	background-color: common.$lightGrey

.Posts
	display: flex
	flex-wrap: wrap
	padding-bottom: 6rem
	max-width: 1200px
	margin: auto
	align-items: stretch
