@use 'common'

.wrapper
	display: flex
	flex-direction: column-reverse
	margin: 0 auto

	@media (min-width: 768px)
		padding: 0 1rem
		flex-direction: row

.sidebar
	padding-top: 48px
	padding-left: 1rem

	@media (min-width: 768px)
		flex-shrink: 0
		width: 400px
		max-width: 30%
	@media (min-width: 992px)
		padding-left: 2rem
	@media (min-width: 1200px)
		padding-left: 3rem
	@media (min-width: 1440px)
		padding-left: 5rem

	.categories
		list-style-type: none
		padding-left: 0

		.categoryBtn
			opacity: 0.5
			&:hover
				opacity: 1
				text-decoration: underline
				color: #000
		.category &
		.isActive
			opacity: 1
			text-decoration: underline
			font-weight: 700

.backToFormBtnWrapper
	display: flex
	flex-direction: column
	padding: 2rem 0

	.backToFormLabel
		font-weight: 700

	.backToFormBtn
		border: 1px solid common.$darkGrey
		background-color: common.$secondaryColor
		width: 60%
		border-radius: 5px
		padding: 0.5rem 1rem
		color: common.$darkGrey

.content
	max-width: 768px
	margin: 0 auto
	.contentWrapper
		@media (min-width: 1440px)
			margin-left: -7.5vw

	.cover
		display: flex
		flex-direction: column
		align-items: center
		padding: 48px 1rem 0 1rem
		@media (min-width:768px)
			flex-direction: row

		.coverTitle
			font-size: 35px
			line-height: 35px
			font-weight: 700
			margin: 0
			width: 100%
			@media (min-width: 425px)
				+common.headline
			@media (min-width: 768px)
				width: 70%

		.coverImage
			width: 100%
			display: flex
			justify-content: center
			margin: 1rem
			@media (min-width: 768px)
				width: 30%

.isHidden
	display: none

.categoriesTitle
	font-size: 20px

.sectionTitle
	+common.headline
	margin-top: 4rem
	padding: 1rem
	p
		margin: 0

.categories
	columns: 2
	padding-left: 1em

	@media (min-width: 768px)
		columns: 1

.category
	& + &
		margin-top: 0.5rem

.pagination
	display: flex
	justify-content: flex-end

.questionsLimit
	margin-top: 2rem
	padding: 0 1rem
	font-weight: 700
	background-color: common.$mainColor
	padding: 2rem
	border-radius: 10px
	a
		text-decoration: underline

.newsletterForm
	padding: 0 1rem
