@use 'common'
@use "sass:math"

.Footer
	background-color: common.$secondaryColor
	display: flex
	position: relative
	z-index: 2
	@media (min-width: 768px)
		justify-content: center
	@media (min-width: 1200px)
		padding: 0 70px 2rem 70px

.FooterBox
	display: flex
	flex-direction: column-reverse
	padding: 2rem 0
	@media (min-width: 435px)
		padding: 2rem
	@media (min-width: 768px)
		display: block

.FooterTextBox
	display: flex
	flex-direction: column
	align-items: flex-start
	@media (min-width: 768px)
		flex-direction: row

.FooterLogo
	display: none
	@media (min-width: 768px)
		display: block
		width: math.div(100%, 4)

.Box
	width: 100%
	padding: 1rem
	@media (min-width: 768px)
		width: math.div(100%, 4)

.Image
	max-width: 200px

.SocialLinks
	display: none
	@media (min-width: 768px)
		display: flex
		margin: 1rem 0

.SocialLinksMobile
	display: flex
	padding: 1rem
	@media (min-width: 768px)
		display: none

.SocialLink
	display: flex
	margin-right: 0.3rem
