@use 'common'

.QuoteBox
	display: flex
	flex-direction: column
	align-items: center
	text-align: center

.QuoteBoxContainer
	max-width: 1200px
	padding: 1rem
	@media (min-width: 768px)
		padding: 2rem
		display: flex

.QuotationMarks
	color: common.$mainColor

.Title
	font-size: 24px
	line-height: 29px
	text-align: left
	margin: 1em

.Text
	+common.smallHeadline
	margin: 1em 1rem
	@media (min-width: 768px)
		margin: 1em 1em
		max-width: 1000px
		+common.headline()

.WithHeadline
	flex-direction: row
	justify-content: space-around
	align-items: flex-start

.WithHeadlineBox
	text-align: left

.WithHeadlineText
	display: flex
	margin: 1em 0
	@media (min-width: 768px)
		margin: 1em 1em

.WithHeadlineText,
.WithHeadlineAuthor
	font-size: 18px
	line-height: 24px

.WithHeadlineAuthor
	font-weight: 400
	margin: 2em 1.5em
	@media (min-width: 768px)
		margin: 2em 3.5em

.WithHeadlineQuestionMarkStart
	display: flex
	align-items: flex-end

.WithHeadlineQuestionMarkStart,
.WithHeadlineQuestionMarkEnd
	margin: 0 0.25em
	font-size: 45px
	line-height: 45px
