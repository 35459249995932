@use 'common'
@use "sass:math"

.Cover
	background-color: common.$secondaryColor

.BannersBox
	display: flex
	justify-content: center
	margin-top: -5rem
	background-color: common.$lightGrey

.Banners
	display: flex
	flex-wrap: wrap
	margin-top: -5rem
	z-index: 2

.BannerContainer
	display: flex
	flex-direction: column
	justify-content: space-between
	width: 100%
	@media (min-width: 768px)
		width: math.div(100%, 2)
	@media (min-width: 992px)
		width: math.div(100%, 3)
	@media (min-width: 1100px)
		width: math.div(100%, 4)

.Banner
	margin: 1rem
	padding: 1.5rem 1rem
	border-radius: 10px
	background-color: common.$secondaryColor

.BannerEmbedBox
	display: flex

.BannerEmbedText
	display: flex
	flex-direction: column
	justify-content: space-between

.BannerEmbedText
	width: 70%
.BannerEmbedImage
	width: 30%

.Title
	+common.smallHeadline

.Subtitle
	color: common.$darkGrey
	margin: 1rem 0

.Button
	display: flex
	align-items: center
	a
		text-decoration: underline
		font-weight: 700

	svg
		margin-left: 0.5rem

.iFrame
	border-radius: 10px
	border: none
