@use 'common'

.Cover
	position: relative
	background-color: common.$secondaryColor
	min-height: 50vh

.DateShareBox
	background-color: common.$secondaryColor
	padding-left: 40px
	padding-bottom: 2rem
	display: flex
	justify-content: space-between
	padding-top: 2rem

	@media (min-width: 768px)
		position: absolute
		padding: 0 3rem
		margin-top: -100px
		padding-bottom: 4rem
		padding-top: 0
	@media (min-width: 992px)
		padding: 0 6rem

.CreatedAt
	border: 1px solid
	width: fit-content
	padding: 0.5em
	border-radius: 5px
	font-weight: 700
	margin: 0

.ShareBtnBox
	position: absolute
	right: 0
	height: 100%
	top: -100px
	right: 10px
	display: flex
	align-items: flex-start

	@media (min-width: 768px)
		top: -125px

.ShareBtn
	z-index: 2
	top: 11rem

	@media (min-width: 768px)
		position: -webkit-sticky
		position: sticky
		padding: 0 3rem
		// margin-top: -100px

	@media (min-width: 992px)
		padding: 0 6rem

.Content
	margin: auto
	position: relative

.LeadParagraphBox
	padding: 1rem

.LeadParagraph
	max-width: 768px
	margin: 0
	margin: auto
	padding: 2rem 0
	font-size: 24px
	line-height: 29px

.AccentBox
	position: absolute
	height: 100%
	width: 100vw
	z-index: 1

.Accent
	position: absolute
	width: 75px
	height: 75px

	@media (min-width:768px)
		width: 100px
		height: 100px

	&:nth-child(1)
		top: 25%
		right: 0
		@media (min-width:768px)
			top: 52%
			left: 0
			width: 75px
			height: 75px

	&:nth-child(2)
		display: none
		@media (min-width:768px)
			display: block
			bottom: 10%
			left: 50%
			margin: auto

	&:nth-child(3)
		display: none
		@media (min-width:768px)
			display: block
			top: 0
			right: 5%
			margin: auto
			width: 75px
			height: 75px

