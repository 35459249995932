@use 'common'
@use "sass:math"

.Cover
	background-color: common.$secondaryColor

.LawBoxes
	background-color: common.$secondaryColor
	margin-top: -1px
	padding: 1rem
	padding-bottom: 100px

.LawContainer
	display: flex
	flex-wrap: wrap
	margin: auto
	// max-width: 1200px

.LawBoxContainer
	height: 100%
	@media (min-width: 768px)
		width: math.div(100%, 2)
	@media (min-width: 992px)
		width: math.div(100%, 3)
	@media (min-width: 1200px)
		width: math.div(100%, 4)

.LawBox
	background-color: #fff
	margin: 12px
	padding: 25px 29px
	border-radius: 10px

.LawTitleBox
	display: flex
	border-bottom: 1px solid #D2D2D2
	padding-bottom: 2rem

.BoxImage
	max-width: 150px

.LawBoxTitle
	+common.smallHeadline
	margin: 0

.BoxList
	padding: 0 1.1rem

.BoxItem
	color: common.$darkGrey
	margin: 1em 0
	&:hover
		color: common.$mainColor
