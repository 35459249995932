@use 'common'

.AccentBox
	position: absolute
	height: 75vh
	width: 100%
	z-index: 1
	pointer-events: none

.Accent
	position: absolute
	width: 75px
	height: 75px

	@media (min-width:768px)
		width: 100px
		height: 100px

	&:nth-child(1)
		top: 10%
		right: 0
		@media (min-width:768px)
			display: block
			bottom: 20%
			right: 0
			left: 15%
			margin: auto
			top: auto

	&:nth-child(2)
		display: none
		@media (min-width:500px)
			display: block
			right: 5%
		@media (min-width:768px)
			top: 30%
			right: 10%
