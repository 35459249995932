@use 'common'

.container
	position: absolute
	width: 100%
	height: 100%
	background-color: rgba(0, 0, 0, 0.3)
	top: 0
	left: 0
	z-index: 21
	&.close
		display: none

.wrapper
	display: flex
	flex-direction: column
	justify-content: space-between
	z-index: 10
	padding: 25px
	background-color: common.$secondaryColor
	position: fixed
	top: 50%
	left: 50%
	transform: translate(-50%, -50%)
	border-radius: 10px
	width: 90%
	max-width: 48rem
	&.close
		display: none
	@media ( min-width: 768px)
		flex-direction: row
		padding: 25px 35px
	@media ( min-width: 992px)
		width: 60%

.wrapperIn
	padding-top: 1.5rem
	padding-right: 1.5rem
	@media ( min-width: 768px )
		padding: 0 1rem 0 0
		width: 70%

.text
	line-height: 1.4
	font-size: 1rem
	margin-bottom: 2rem

.title
	display: flex
	justify-content: space-between
	font-weight: 700
	margin-bottom: 15px
	font-size: 20px
	@media ( min-width: 768px )
		font-size: 30px

.newsletterLink
	display: flex
	align-items: center
	margin-right: 0.5rem
	border: none
	background-color: transparent
	font-size: 16px
	line-height: 19.2px
	font-weight: 700
	padding: 0
	cursor: pointer
	&:hover
		text-decoration: underline
		.arrow
			transform: translateX(10px)

.arrow
	margin-left: 0.5rem
	transition: 0.3s ease-in-out

.link
	width: fit-content
	margin-right: 0.5rem
	a
		text-decoration: none
		&:hover
			text-decoration: underline

.iFrame
	border-radius: 10px
	border: none
	background-color: #fff

.image
	display: flex
	align-items: flex-start
	padding-right: 1.5rem
	padding-top: 1.5rem
	max-width: 15.625rem
	margin: auto
	@media ( min-width: 768px )
		margin: 0
		max-width: none
		width: 30%

.crossButton
	position: absolute
	top: 1.5rem
	right: 1.5rem
	cursor: pointer
	width: 20px
	display: flex
	justify-content: space-between
	&:hover
		.line1,
		.line2
			background: darken(#000, 20%)

.line1,
.line2
	width: 2px
	height: 25px
	background: #000
	border-radius: 3px

.line1
	transform: rotate(-45deg)
	transform-origin: top right

.line2
	transform: rotate(45deg)
	transform-origin: top left
