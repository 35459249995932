@use 'common'
@use "sass:math"

.PartnersContainer
	padding: 1rem 0
	background-color: white
	@media (min-width: 768px)
		padding: 2rem 0
	@media (min-width: 992px)
		padding: 3rem 0

.Highlighted
	background-color: #fff

.PartnersBox
	margin: auto
	max-width: 1200px

.Partners
	display: flex
	justify-content: center

.Partner
	display: block
	padding: 2rem
	max-width: 16rem
	margin: auto

.Arrow
	border: none
	background-color: transparent
	cursor: pointer
	margin: auto

.Dots
	display: flex
	justify-content: center

.Dot
	width: 12px
	height: 12px
	background-color: #979797
	border: none
	margin: 1rem
	border-radius: 50%

.isActive
	background-color: common.$mainColor

.Slide
	display: flex
	justify-content: center
	align-items: center
