@use 'common'

.NumberedList
	max-width: common.$pageWidth768
	margin: auto
	padding: 2rem

.Text
	+common.headline()
	text-align: center

.Subtitle
	font-size: 24px
	line-height: 29px

.Number
	+common.headline()
	display: flex
	font-weight: 700
	margin: 3rem 0 1rem
