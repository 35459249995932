@use 'common'

.Breadcrumbs
	background-color: common.$secondaryColor
	padding: 0 20px 15px
	position: relative
	z-index: 3
	@media (min-width: 768px)
		padding: 40px
		padding: 1rem 6rem

.Breadcrumb
	&:nth-child(1)
		font-weight: 700

	&:hover
		color: common.$mainColor

.Dash
	margin: 0 0.5rem

