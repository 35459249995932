@use 'common'

.HeaderSticky
	position: sticky
	top: -1px
	z-index: 4
	margin-bottom: -1px

.HamburgerMobile
	display: flex
	justify-content: space-between
	background-color: common.$secondaryColor
	padding: 1rem
	position: relative
	@media (min-width: 768px)
		display: none

.ImageMobile
	max-width: 45px
	max-height: auto

.HamburgerButton
	border: none
	background-color: common.$secondaryColor
	padding: 0
	display: flex

.HamburgerClose
	width: 100%
	display: flex
	justify-content: flex-end
	padding: 1rem
	position: absolute
	@media (min-width: 768px)
		display: none

.Header
	display: none
	flex-direction: column
	background-color: common.$secondaryColor
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
	@media (min-width: 768px)
		box-shadow: none
		display: flex
		justify-content: space-between
		flex-direction: row
		align-items: center
		height: 70px

.Image
	max-width: 200px
	padding: 1rem
	padding-right: 0
	@media (min-width: 768px)
		margin-left: 20px
		padding: 0

.LinksBox
	display: flex
	justify-content: space-between
	padding: 2rem
	@media (min-width: 768px)
		padding: 0
		align-items: center
		flex-direction: row
		margin-right: 20px

.Links
	display: flex
	flex-direction: column-reverse
	max-width: 85%
	@media (min-width: 768px)
		flex-direction: row
		align-items: center
		max-width: 100%

.LinkContainer
	display: flex
	flex-direction: column
	@media (min-width: 768px)
		flex-direction: row

.Link
	padding: 0 1rem
	color: rgba(0, 0, 0, 0.6)
	&:hover
		color: common.$mainColor

.LinkMobile
	padding: 0.5rem
	@media (min-width: 768px)
		padding: 0 1rem

.IsActiveLink
	color: #000
	font-weight: 700
	border-bottom: 1px solid #000

.isActive
	display: flex
	justify-content: space-between
	top: 0
	left: 0
	right: 0
	bottom: 0
	border-radius: 10px
	overflow: hidden
	@media (max-width: 768px)
		height: fit-content
		position: absolute

.Link, .Button
	font-weight: 700

.ButtonDesktop,
.ButtonMobile
	padding: 0.25rem 0.5rem 0.25rem 1rem
	background-color: common.$mainColor
	display: flex
	flex-direction: column
	border-radius: 30px

	@media (min-width: 768px)
		align-items: center

.ButtonDesktop
	display: none
	@media (min-width: 768px)
		display: block

.MobileDonateBox
	width: 100vw
	margin: 0 -2rem
	padding: 1rem
	@media (min-width: 768px)
		display: none

.ButtonMobile
	border-radius: 10px
	margin: 1rem 0
	@media (min-width: 768px)
		display: none
	.ButtonLink
		margin: 1rem 0

.ButtonLink
	display: flex
	align-items: center
	font-weight: 700

.ButtonDescriptionBox
	display: flex
.ButtonDescription
	+common.smallHeadline
	max-width: 175px

.LanguageSwitcher
	display: flex
	align-items: flex-end
	padding: 0.5rem
	text-transform: capitalize
	@media (min-width: 768px)
		padding: 0

.Language
	margin: 0 0.2rem

.Active
	color: #000
	padding-right: 0
	margin-right: -0.5rem
	text-decoration: underline
	@media (min-width:768px)
		text-decoration: none

	&:hover
		color: #000
