@use 'common'
@use "sass:math"

.IconsWithHeadlineBox
	max-width: 1200px
	margin: auto
	padding: 50px 0 50px 0

	@media (min-width: 1440px)
		padding: 100px 0 100px 0

.IconsWithHeadlineContainer
	max-width: 1200px
	margin: auto

.IconsWithHeadline
	display: flex
	margin: 0 1rem

	@media (max-width: 768px)
		flex-direction: column
		align-items: center

.Box
	display: flex
	flex-direction: column
	align-items: center
	justify-content: center
	padding: 1rem

	@media (min-width: 768px)
		width: math.div(100%, 4)

.Title
	+common.headline

.IconText
	+common.smallHeadline
	max-width: 130px

.LinkBox
	margin: 4rem 0 0 1rem
	display: flex
	align-items: center

.Link
	text-decoration: underline
	margin-right: 0.75rem
	font-weight: 700
	padding: 1rem
