@use 'common'

.overlay
	position: fixed
	top: 0
	left: 0
	width: 100%
	height: 100%
	display: flex
	justify-content: center
	align-items: center
	padding: 20px
	background-color: rgba(0, 0, 0, 0.3)
	z-index: 22

.content
	position: relative
	outline: none
	width: 100%
	max-width: 1000px
	inset: initial

	min-width: 60vw

	border-radius: 10px
	margin: 1rem

.contentIn
	display: flex
	flex-direction: column
	background-color: common.$mainColor
	padding: 1rem
	overflow-y: scroll
	max-height: 70vh
	border-radius: 10px

	@media (min-width: 768px)
		padding: 2rem 3rem

.close
	position: absolute
	top: -16px
	left: -16px
	cursor: pointer
	padding: 0
	background: transparent
	border: none
