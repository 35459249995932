@use 'common'

.VideoBox
	padding: 3rem 1rem
	max-width: 768px
	margin: auto

	@media ( min-width: 992px)
		padding: 4rem 1rem

.VideoBoxBig
	max-width: none

.Video
	max-width: 1024px
	margin: auto
	border-radius: 10px
	position: relative
	cursor: pointer

.TextBox
	display: flex
	flex-direction: column
	margin: auto
	background-color: common.$mainColor
	padding: 2rem
	margin-top: 12px
	position: relative
	border-radius: 10px
	z-index: 2
	@media (min-width: 768px)
		width: 350px
		margin-top: -90px

.TitleBox
	display: flex
	margin-bottom: 2rem

.Title
	+common.smallHeadline()
	margin: 0
	max-width: 165px
	z-index: 1

.Heart
	margin-left: -30px
	margin-top: -30px

.Link
	font-size: 16px
	line-height: 19,2px
	text-align: center
