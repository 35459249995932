@use 'common'
@use "sass:math"

.BlogPost
	width: 100%
	padding: 15px 0
	margin: 0 1rem
	border-bottom: 1px solid #D2D2D2
	display: flex
	flex-direction: row-reverse
	justify-content: flex-end
	@media (min-width:768px)
		&:hover
			.BlogPostImage
				.Wrapper
					transform: scale(1.05)
	&:nth-child(4n+1)
		flex-direction: column

		.BlogPostImage
			width: 100%

	// @TODO should it be visible or hidden
		// .BlogPostSubtitle
		// 	display: flex

	&:nth-child(4n+5),
	&:nth-child(4n+2)
		margin-top: 35px
		@media (min-width: 768px)
			margin-top: 0

	@media (min-width: 768px)
		margin-top: 75px
		padding: 70px 10px 10px
		margin: 0 45px
		display: flex
		flex-direction: column
		width: calc(100% / 2 - 90px)
	@media (min-width: 992px)
		width: calc(100% / 3 - 90px)

	[data-multiline="true"] &
		&:nth-child(4n+1)
			width: 100%
			display: flex

			.TextBox
				@media (min-width: 768px)
					width: math.div(100%, 3)
					padding-right: 2rem

			.BlogPostImage
				@media (min-width: 768px)
					width: 66%

			.ButtonBox
				display: none
				@media (min-width: 768px)
					display: flex

			.BlogPostTitle
				@media (min-width: 768px)
					+common.headline

.BlogPostTitle
	font-size: 20px
	line-height: 24px
	margin: 0
	@media (min-width: 768px)
		+common.smallHeadline

.BlogPostSubtitle
	display: none
	font-size: 16px
	line-height: 25px
	@media (min-width: 768px)
		display: block

.TextBox
	width: 70%
	margin: 0 1rem
	@media (min-width: 768px)
		width: 100%
		margin: 0
		margin-bottom: auto

.BlogPostImage
	overflow: hidden
	position: relative
	border-radius: 10px
	width: 30%
	.Wrapper
		width: 100%
		height: 100%
		position: absolute
		top: 0
		transition: 0.3s ease-in-out

	@media (min-width: 768px)
		width: 100%

	&.HideThumbnail
		display: none

	&::before
		display: block
		content: ''
		padding-top: 56.25%
		height: 0

.ButtonBox
	display: none
	align-items: center
	margin: 1rem 0
	text-decoration: underline
	font-weight: 700

.Arrow
	margin-left: 0.4rem

.isBlogPage
	&:nth-child(4n+1)
		@media (min-width:768px)
			display: block
			flex-direction: row
			.TextBox
				width: 100%
				margin: 0

.withoutThumbnail
	transition: 0.3s ease-in-out
	&:hover
		transform: scale(1.05)
