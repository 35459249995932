@use 'common'
@use 'sass:math'

.Container
	display: block
	position: relative

	.Ratio
		width: 100%
		padding-top: math.div(9 * 100%, 16)

	.Video,
	.Poster
		position: absolute
		top: 0
		right: 0
		left: 0
		bottom: 0
		width: 100%
		height: 100%
		opacity: 1
		transition: opacity ease-in-out .3s

	.Video
		border-radius: 10px

	.Poster
		pointer-events: none
		border-radius: 10px
		overflow: hidden

	&.cover .Video
		border-radius: 0

	&.videoReady .Video
		opacity: 1

	&.posterLoaded .Poster
		opacity: 1

	&.videoReady .Poster
		opacity: 0

	&.isPlaying .PlayBtn
		opacity: 0

.PlayBtn
	position: absolute
	z-index: 2
	top: 0
	right: 0
	bottom: 0
	left: 0
	display: flex
	justify-content: center
	align-items: center
	pointer-events: none

.ForcePosterBeforePlay
	&.videoReady .Video
		opacity: 1
	&.videoReady .Poster
		opacity: 1
		z-index: 2
	&.isPlaying .Poster
		opacity: 0
	&.isPlaying .Video
		opacity: 1
