@use 'common'
@use 'sass:math'

.CourseDetail
	display: flex
	flex-wrap: wrap
	margin-left: 1rem
	max-width: 600px
	margin-top: 1rem
	margin-bottom: 1rem
	@media (min-width: 768px)
		flex-direction: column
		margin-left: 45px
		margin-bottom: 1.5rem
	@media (min-width: 992px)
		margin-left: 68px
		margin-bottom: 2rem
	@media (min-width: 1200px)
		margin-left: 90px
		max-width: 768px

.InfoBox
	display: flex
	flex-direction: column
	margin: 3px 0
	margin-bottom: 2rem
	margin-right: 1rem
	width: 100%
	@media (min-width: 768px)
		width: 100%
		flex-direction: row
		margin-bottom: 3px
		margin-right: 0
		align-items: center

.InfoLabel
	background-color: common.$secondaryColor
	border-radius: 5px
	min-width: 90px
	width: 90px
	height: 45px
	display: flex
	justify-content: center
	align-items: center
	font-weight: 700
	margin: 0.25rem 1rem 0.25rem 0
	font-size: 0.9rem

.InfoDescription
	color: common.$darkGrey
	margin-right: 2rem
	display: flex
	margin-left: 0.5rem
	margin-top: 0.25rem

	@media (min-width: 768px)
		margin-top: 0rem
		margin-bottom: 0rem
		&::before
			content: "•"
			margin-right: 0.4rem

.InfoValue
	display: flex
	align-items: center
	font-weight: 700
	margin-left: 0.5rem
	margin-top: 0.5rem

	p
		margin: 0
	@media (min-width: 768px)
		margin-top: 0
		margin-bottom: 0

.Price
	display: flex
	flex-direction: column
	@media (min-width: 768px)
		flex-direction: row
		align-items: center

.Currency
	padding-left: 0.5rem

.PriceBox
	display: flex

.Dates
	display: flex
	flex-wrap: wrap
	margin-left: 0.5rem
	align-items: center
	font-weight: 700
	max-width: 100px
	margin-top: 0.5rem
	@media (min-width: 768px)
		max-width: 100%

.CustomBox
	display: flex
	flex-direction: column
	@media (min-width: 768px)
		flex-direction: row
.Coma
	margin-right: 0.5rem
