@use 'common'
@use "sass:math"

.CoursesBox
	display: flex
	flex-direction: column
	align-items: center
	text-align: center
	padding: 0 1rem

.TitleBox
	padding: 3rem 0 1rem

.Title
	+common.headline
	margin: 0

.Courses
	display: flex
	flex-direction: column
	flex-wrap: wrap
	margin: 2rem 0
	width: 100%
	max-width: 1200px
	@media (min-width: 768px)
		flex-direction: row
		align-items: stretch

.Course
	display: flex
	width: 100%
	max-width: 300px
	transition: 0.3s ease-in-out

	@media (min-width: 768px)
		width: math.div(100%, 2)
	@media (min-width: 992px)
		width: math.div(100%, 3)
	@media (min-width: 1200px)
		width: math.div(100%, 4)
	&:hover
		transform: scale(1.05)

.ButtonBox
	display: flex
	align-items: center
	text-decoration: underline
	font-weight: 700
	width: 100%
	margin: 1rem
	max-width: 1200px
	padding: 0 1rem
	margin-top: -1rem
	@media (min-width: 768px)
		justify-content: flex-end
		margin-top: 1rem

.Arrow
	margin-left: 0.4rem
