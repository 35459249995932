@use 'common'

.ShareHeadlineBox
	display: flex
	align-items: center
	max-width: 768px
	margin: auto
	padding: 2rem
	visibility: hidden
	display: none

.Supported
	visibility: inherit
	display: flex

.ShareHeadline
	+common.smallHeadline
	padding: 1rem 0
	margin: 0
	padding-right: 0.5rem

.ShareButtons
	border: none
	background: none
	cursor: pointer
	margin: 0
	padding: 0
