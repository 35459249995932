@use 'common'
@use "sass:math"

.Cover
	background-color: common.$secondaryColor

.TopicTitle
	+common.headline
	text-align: center
	margin: 5rem

	@media (max-width: 768px)
		margin: 5rem 0 3rem 0

.LeadParagraph
	margin: 0

.CoursesBox
	background-color: common.$lightGrey

.Courses
	display: flex
	flex-direction: column
	flex-wrap: wrap
	margin: 2rem 0
	width: 100%
	max-width: 1250px
	margin: auto
	@media (min-width: 768px)
		flex-direction: row

.Course
	display: flex
	width: 100%
	min-width: 300px
	max-width: 335px
	transition: 0.3s ease-in-out
	@media (min-width: 768px)
		width: math.div(100%, 2)
	@media (min-width: 992px)
		width: math.div(100%, 3)
	@media (min-width: 1200px)
		width: math.div(100%, 4)
	&:hover
		transform: scale(1.05)

.CourseSection
	&:nth-last-child(1)
		margin-bottom: 6rem
